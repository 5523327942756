<template>
  <div class="Reports__View">
    <EntityListLayout v-if="tabs.length" :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>מקבצי פסיקה</template>
      <template #button>
        <a @click="addVerdictCollection" class="link-button">
           מקבץ פסיקה חדש
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import AdminService from "@/services/AdminService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import DialogEditVerdict from '@/components/DialogEditVerdict.vue';
import {
  ModalProgrammatic as Modal,
} from 'buefy';
import FieldService from "@/services/FieldService";

export default {
  name: "Reports",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const lessonsFields = FieldService.list("verdicts");
      const fields = [
        { ...lessonsFields.Id, checked: true },
        { ...lessonsFields.Name, checked: true },
      ];

      this.tabs = [
        {
          id: "AllVerdictsCollection",
          isPrimary: true,
          title: "כל המקבצים",
          getDataAsync: this.getAllVerdictCollections,
          perPage: 100,
          fields,
          filters: null,
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: false,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש מהיר",
          },
          sideViewModal: DialogEditVerdict,
        },
      ];
    },
    addVerdictCollection() {
      Modal.open({
        component: DialogEditVerdict,
        props: {
          store: this.$store
        },
        canCancel: false,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    getAllVerdictCollections({ ...rest }) {
      return AdminService.getAdminVerdictCollections(rest).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
  },
};
</script>
<style lang="scss">
.Reports__View {
  height: 100%;
}
.link-button {
  background: #ff7a59;
  border: none;
  border-radius: 3px;
  padding: 14px 25px;
  color: white;
  cursor: pointer;
}
.not-activated-row {
  background-color: #fff !important;
}
</style>
