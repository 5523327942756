var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Profile__Page" },
    [
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          { staticClass: "Profile__Page__Menu" },
          [
            _c("h1", [_vm._v("אדמיניסטרציה")]),
            _c(
              "b-menu",
              [
                _c(
                  "b-menu-list",
                  [
                    _c("b-menu-item", {
                      attrs: {
                        label: "ניהול קורסים",
                        tag: "router-link",
                        to: "/admin/profiles"
                      }
                    }),
                    _c("b-menu-item", {
                      attrs: {
                        label: "ניהול משתמשים",
                        tag: "router-link",
                        to: "/admin/users"
                      }
                    }),
                    _c("b-menu-item", {
                      attrs: {
                        label: "בנק שאלות",
                        tag: "router-link",
                        to: "/admin/questionsbank"
                      }
                    }),
                    _c("b-menu-item", {
                      attrs: {
                        label: "בנק וידאו",
                        tag: "router-link",
                        to: "/admin/videosbank"
                      }
                    }),
                    _c("b-menu-item", {
                      attrs: {
                        label: "בנק ספרים",
                        tag: "router-link",
                        to: "/admin/booksbank"
                      }
                    }),
                    _c("b-menu-item", {
                      attrs: {
                        label: "קופונים",
                        tag: "router-link",
                        to: "/admin/coupons"
                      }
                    }),
                    _c(
                      "b-menu-item",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "label",
                            fn: function(props) {
                              return [
                                _vm._v(" פעולות "),
                                _c("b-icon", {
                                  staticClass: "is-pulled-left",
                                  attrs: {
                                    icon: props.expanded
                                      ? "menu-up"
                                      : "menu-down"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c("b-menu-item", {
                          attrs: {
                            label: "עדכון חוקים חסרים לתרגולים",
                            tag: "router-link",
                            to: "/admin/action/updateexerciselaws"
                          }
                        }),
                        _c("b-menu-item", {
                          attrs: {
                            label: "עריכת מקבצי חקיקה",
                            tag: "router-link",
                            to: "/admin/action/updatelawcollections"
                          }
                        }),
                        _c("b-menu-item", {
                          attrs: {
                            label: "שכפול יחידה",
                            tag: "router-link",
                            to: "/admin/action/unitReplication"
                          }
                        }),
                        _c("b-menu-item", {
                          attrs: {
                            label: "ניהול תקצירי פסיקה",
                            tag: "router-link",
                            to: "/admin/action/verdictReplication"
                          }
                        }),
                        _c("b-menu-item", {
                          attrs: {
                            label: "יצירת קובץ תשובות",
                            tag: "router-link",
                            to: "/admin/action/createanswersfiles"
                          }
                        }),
                        _c("b-menu-item", {
                          attrs: {
                            label: "עריכת מקבצי פסיקה",
                            tag: "router-link",
                            to: "/admin/action/verdictscollections"
                          }
                        }),
                        _c("b-menu-item", {
                          attrs: {
                            label: "ניהול רשימת חוקים",
                            tag: "router-link",
                            to: "/admin/action/manageLawsCollections"
                          }
                        }),
                        _c("b-menu-item", {
                          attrs: {
                            label: "עדכון אורכים ותמונות חסרות לוידאו",
                            tag: "router-link",
                            to: "/admin/action/updateVideoContent"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "Profile__Page__Content" },
        [_vm.ready ? _c("router-view") : _vm._e()],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }