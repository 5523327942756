<template>
  <div id="unitReplication">
    <h1>שכפול יחידת לימוד</h1>
    <form @submit.prevent="onSubmit">
      <h3>תחילה יש לבחור את יחידת הלימוד שתשוכפל</h3>
      <div class="loading">
        <b-loading :is-full-page="true" v-model="loading" :can-cancel="false" />
      </div>
      <div class="input-section">
        <label for="from-course">מקורס</label>
        <select @change="searchUnit(model.fromCourseId)" v-model="model.fromCourseId">
          <option v-for="course in courses" :value="course.id">{{ course.name }}</option>
        </select>
      </div>
      <div style="margin-bottom: 8px" class="input-section">
        <label for="to-unit">מיחידת לימוד</label>
        <select v-if="units" v-model="model.lessonId">
          <option v-for="unit in units.lessons" :value="unit.id">{{ unit.name }}</option>
        </select>
        <select :class="[!units && 'disabled']" v-else>
          <option value="בחר קודם קורס">בחר קודם קורס</option>
        </select>
      </div>
      <div class="input-section" style="margin-bottom: 5px">
        <label for="to-course">לקורס</label>
        <select v-model="model.toCourseId">
          <option v-for="course in courses" :value="course.id">{{ course.name }}</option>
        </select>
      </div>
      <div class="input-section">
        <b-button :loading="loading" :disabled="!model.fromCourseId" @click="onSubmit" type="submit">שכפול יחידת לימוד</b-button>
      </div>
    </form>
    <div class="pay-attention">
      <p>שימו לב:</p>
      <p>1. יחידת הלימוד תשוכפל לקורס הרצוי.</p>
      <p>2. יחידת הלימוד המשוכפלת תהיה בסטטוס 0.</p>
      <p>3. ניתן לשכפל יחידת לימוד רק עם הרשאות מנהל.</p>
    </div>
  </div>
</template>

<script>
import { ToastProgrammatic as Toast } from 'buefy';
const BASE_URL = process.env.VUE_APP_APIURL;
export default {
  name: 'unitReplication',
  props: [],
  data: function () {
    return {
      courses: null,
      units: null,
      model: {
        fromCourseId: null,
        toCourseId: null,
        lessonId: null
      },
      loading: false
    }
  },
  created() {
    this.$api.get(BASE_URL + '/api/Courses')
      .then((data) => {
        data.data.unshift({ id: null, name: 'בחר קורס' });
        this.courses = data.data;
      }).catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    onSubmit() {
      this.loading = true;
      this.$api.post(BASE_URL + '/api/Admin/Lesson/' + this.model.lessonId + '/Duplicate/' + this.model.toCourseId)
        .then(() => {
          this.loading = false;
          Toast.open({
            type: 'is-success',
            message: 'פעולה בוצעה בהצלחה!',
            position: 'is-top',
            duration: 8000
          });
          this.model.fromCourseId = null;
          this.model.toCourseId = null;
          this.units = null;
          this.model.lessonId = null;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          Toast.open({
            type: 'is-danger',
            message: 'פעולה נכשלה!',
            position: 'is-top',
            duration: 8000
          });
        })
    },
    searchUnit(id) {
      this.$api.get(BASE_URL + '/api/Courses/' + id)
        .then((data) => {
          data.data.lessons.unshift({ id: null, name: 'בחר יחידת לימוד' });
          this.units = data.data;
        }).catch((error) => {
          console.log(error);
        });
    }
  }
}
</script>

<style scoped>
#unitReplication {
  width: 50%;
  padding: 20px;
}

#unitReplication form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#unitReplication .input-section {
  width: 50%;
}

#unitReplication .input-section select {
  width: 50%;
}

#unitReplication button {
  width: 100%;
  background-color: #00427E;
  opacity: .6;
  padding: 10px;
  border-radius: 7px;
  color: white;
}

#unitReplication button:hover {
  opacity: 1;
}

#unitReplication .disabled {
  pointer-events: none !important;
}

#unitReplication select {
  cursor: pointer;
}

#unitReplication h1,
#unitReplication h2,
#unitReplication h3 {
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: #111;
}

#unitReplication h1 {
  font-size: 2em;
}

#unitReplication h3 {
  font-size: 1.17em;
}
</style>
