var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogAddLaw__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v(_vm._s(_vm.isNew ? "הוספת" : "עדכון") + " חוק")
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogAddLaw__Component__Loading" },
          [
            _c("FieldInlineText", {
              attrs: { label: "שם" },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.name,
                callback: function($$v) {
                  _vm.$set(_vm.model, "name", $$v)
                },
                expression: "model.name"
              }
            }),
            _c("FieldInlineText", {
              attrs: { label: "מזהה חוק דינמי" },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.dymanicId,
                callback: function($$v) {
                  _vm.$set(_vm.model, "dymanicId", $$v)
                },
                expression: "model.dymanicId"
              }
            }),
            _c(
              "div",
              { staticClass: "clauses" },
              _vm._l(_vm.clauses, function(clause) {
                return _c(
                  "div",
                  {
                    key: clause.id,
                    staticClass: "clause",
                    class: clause.dymanicId ? "pointer" : "disabled"
                  },
                  [_vm._v(" " + _vm._s(clause.name) + " ")]
                )
              }),
              0
            ),
            !_vm.isNew
              ? _c(
                  "b-button",
                  {
                    staticStyle: { "margin-top": "10px" },
                    attrs: { loading: _vm.isLoading },
                    on: {
                      click: function($event) {
                        return _vm.updateLawClauses(_vm.model.id)
                      }
                    }
                  },
                  [_vm._v("עדכון סעיפי חוק")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }