<template>
    <div class="DialogAddLaw__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ isNew ? 'הוספת' : 'עדכון' }} חוק</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogAddLaw__Component__Loading">
                    <FieldInlineText label="שם" @onChange="onChange" v-model="model.name" />

                    <FieldInlineText label="מזהה חוק דינמי" @onChange="onChange"
                        v-model="model.dymanicId" />

                    <div class="clauses">
                        <div :class="clause.dymanicId ? 'pointer' : 'disabled'" v-for="clause in clauses" :key="clause.id" class="clause">
                            {{ clause.name }}
                        </div>
                    </div>

                    <b-button v-if="!isNew" :loading="isLoading" @click="updateLawClauses(model.id)"
                        style="margin-top: 10px;">עדכון
                        סעיפי חוק</b-button>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import FieldInlineText from "./Fields/FieldInlineText.vue";
import AdminService from '../services/AdminService';

export default {
    name: "DialogEditLaw",
    props: ["store", "row", "isNew"],
    components: {
        FieldInlineText
    },
    computed: {
    },
    created() {
        if (!this.isNew) {
            this.model = { ...this.row };
            this.getLawClauses(this.model.id);
        }
    },
    data() {
        return {
            isLoading: false,
            model: {
                name: null,
                dymanicId: null,
            },
            clauses: null
        };
    },
    methods: {
        getLawClauses(id) {
            AdminService.getLawClauses(id)
                .then((r) => {
                    this.clauses = r.data;
                });
        },
        updateLawClauses(id) {
            this.isLoading = true;
            AdminService.updateLawClauses(id)
                .then(() => {
                    Toast.open({
                        type: "is-success",
                        message: "הפעולה בוצעה!",
                        duration: 4000,
                    });
                    this.$emit("close");
                    window.location.reload();
                }).catch(() => {
                    Toast.open({
                        type: "is-danger",
                        message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                        duration: 8000,
                    });
                }).finally(() => {
                    this.isLoading = false;
                });
        },
        save() {
            if (this.model.name) {
                if (this.isNew) {
                    this.isLoading = true;
                    AdminService.createLaw({
                        dymanicId: this.model.dymanicId,
                        name: this.model.name
                    })
                        .then(() => {
                            Toast.open({
                                type: "is-success",
                                message: "הפעולה בוצעה!",
                                duration: 4000,
                            });
                            this.$emit("close");
                            window.location.reload();
                        }).catch(() => {
                            Toast.open({
                                type: "is-danger",
                                message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                                duration: 8000,
                            });
                        }).finally(() => {
                            this.isLoading = false;
                        });
                } else {
                    this.isLoading = true;
                    AdminService.updateLaw(this.model.id, {
                        ...this.model
                    })
                        .then(() => {
                            Toast.open({
                                type: "is-success",
                                message: "הפעולה בוצעה!",
                                duration: 4000,
                            });
                            this.$emit("close");
                            window.location.reload();
                        }).catch(() => {
                            Toast.open({
                                type: "is-danger",
                                message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                                duration: 8000,
                            });
                        }).finally(() => {
                            this.isLoading = false;
                        });
                }
            }
        },
        onChange() { },
    },
};
</script>

<style scoped lang="scss">
.clauses {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: 400px;
    overflow: auto;
}

.modal-card {
    width: 1000px;
}

.clause {
    min-width: 40px;
    height: 30px;
    background: #fff;
    border: 1px solid #333;
    border-radius: 4px;
    color: #333;
    padding: 5px;
    margin: 5px 0 5px 10px;
}
.pointer {
    cursor: pointer;
}
.disabled {
    background: #f0f2f5;
    color: #aaa;
    border-color: #aaa;
}
</style>
