<template>
  <div class="LessonEntityDetails__Component">
    <FieldInlineText label="שם התקציר" @onChange="onChange" v-model="model.name" />

    <FieldInlineText label="נושא ראשי" @onChange="onChange" v-model="model.category" />

    <FieldInlineText label="נושא משני" @onChange="onChange" v-model="model.subCategory" />

    <b-taginput @input="onChange" v-model="model.modelSubLabels" ellipsis icon="label" placeholder="הוסף סוגיה"
      aria-close-label="מחק סוגיה זו">
    </b-taginput>

    <FieldInlineText :type="'number'" label="מספר פסק דין" @onChange="onChange" v-model="model.nevoVerdictId" />

  </div>
</template>

<script>
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
// import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
// import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
// import FieldInlineDateTime from '@/components/Fields/FieldInlineDateTime.vue';
// import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import { mapGetters, mapMutations } from 'vuex';
import CoursesService from '../services/CoursesService';

export default {
  name: 'LessonEntityDetails',
  components: {
    FieldInlineText,
    // FieldInlineDate,
    // FieldInlineSelect,
    // FieldInlineTextArea,
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        id: null,
        name: null,
        category: null,
        facts: null,
        halachot: null,
        miniRatio: null,
        modelSubLabels: null,
        nevoVerdictId: null,
        nevoVerdictUrl: null,
        resolution: null,
        subCategory: null,
        summary: null,
        verdict: null
      },
    };
  },
  computed: {
    ...mapGetters('verdictSummary', [
      'form',
    ]),
    courseId() {
      return this.$store.state.auth.course?.id;
    },
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    getCategories() {
      return () =>
        CoursesService.getCategories().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    getLawCollections() {
      return () =>
        CoursesService.getLawCollections().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeLessonDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('verdictSummary', [
      'storeLessonDetails',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  gap: 10px;
  margin-top: 10px;
}

.laws-items {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.law-item {
  display: flex;
  align-items: center;
  gap: 10px;

  p {
    flex: 1;
    font-weight: bold;
  }
}
</style>
