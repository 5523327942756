<template>
  <div class="Reports__View">
    <EntityListLayout :tabs="tabs" ref="entity" @onNewEntitySave="onNewEntitySave">
      <template #title>בנק שאלות</template>
      <template #button>
        <a @click="addQuestion" class="link-button">
          שאלה חדשה
        </a>
      </template>
      <template v-slot:counter="slotProps">
        סה"כ <b>{{ getTotalRecords(slotProps) }} </b>
      </template>
    </EntityListLayout>
  </div>
</template>
<script>
import AdminService from "@/services/AdminService";
import EntityListLayout from "@/components/EntityListLayout.vue";
import FieldService from "@/services/FieldService";
import FilterService from "@/services/FilterService";
import QuestionEntity from "./QuestionEntity.vue";

export default {
  name: "QuestionsInBank",
  data() {
    return {
      tabs: [],
    };
  },
  components: {
    EntityListLayout,
  },
  created() {
    this.create();
  },
  methods: {
    create() {
      const reportsFields = FieldService.list("questions");
      const reportsfilters = FilterService.list();

      const sideViewEntity = {
        component: QuestionEntity,
        preview: true,
        readOnly: false,
        title: "פרטי שאלה",
        recordTitle: 'הצג שאלה',
        recordUrl: "question",
      };
      const fields = [
        { ...reportsFields.Id, checked: true },
        { ...reportsFields.Question, checked: true },
        { ...reportsFields.EditorNotes, checked: true },
        { ...reportsFields.IsDelta, checked: true },
        { ...reportsFields.IsInExam, checked: true },
        { ...reportsFields.Entity, checked: true },
      ];

      this.tabs = [
        {
          id: "QuestionsInBank",
          title: "כל השאלות",
          getDataAsync: this.getAllQuestions,
          perPage: 100,
          fields,
          filters: [
          { ...reportsfilters.ExerciseId },
          { ...reportsfilters.AcademicInstituteId },
          { ...reportsfilters.AuthorId },
          { ...reportsfilters.QuestionCategoryIds },
          { ...reportsfilters.LawId },
          { ...reportsfilters.MinimumStudentAnswers },
          { ...reportsfilters.HasOpenRequestsOnly },
          { ...reportsfilters.IsActive, selected: true },
          { ...reportsfilters.IsDelta },
          { ...reportsfilters.IsInExam },
          { ...reportsfilters.IsInactive },
          { ...reportsfilters.HasEditorNotesOnly },
          { ...reportsfilters.MinimumDifficulty },
          { ...reportsfilters.MaximumDifficulty },
          { ...reportsfilters.SearchFilterType },
          ],
          sortField: "Id",
          sortOrder: "desc",
          checkableRows: false,
          fastSearch: true,
          fastSearchOptions: {
            propName: "Terms",
            label: "חיפוש שאלה בבנק",
          },
          sideViewEntity,
          tableActions: null,
          isAdminArea: true,
          defaultQuery: false
        },
      ];
    },
    onNewEntitySave() {
      this.$refs.entity.onNewEntityCancel();
    },
    getAllQuestions({ cancelToken, ...rest }) {
      return AdminService.getQuestionsInBank(
        {
          ...rest,
          IsAscending: false
        },
        cancelToken.token
      ).then((r) => ({
        data: {
          TotalResults: r.data.totalResults,
          Items: r.data.items,
        },
      }));
    },
    getTotalRecords(slotProps) {
      return slotProps.tab && slotProps.tab.total && slotProps.tab.total;
    },
    addQuestion() {
      this.$router.push('/newQuestion');
    }
  },
};
</script>
<style lang="scss" scoped>
.Reports__View {
  height: 100%;

  .link-button {
    background: #ff7a59;
    border: none;
    border-radius: 3px;
    padding: 14px 25px;
    margin-left: 10px;
    color: white;
    cursor: pointer;
  }
}
</style>
