var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Admin__View" }, [
    _c("div", { staticClass: "Admin__View__Profiles" }, [
      _c(
        "div",
        {
          staticClass:
            "is-flex is-justify-content-space-between is-align-items-center"
        },
        [
          _c(
            "div",
            { staticClass: "is-flex" },
            [
              _c(
                "div",
                { staticClass: "Field" },
                [
                  _c(
                    "b-field",
                    { attrs: { label: "חפש" } },
                    [
                      _c("b-input", {
                        model: {
                          value: _vm.term,
                          callback: function($$v) {
                            _vm.term = $$v
                          },
                          expression: "term"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-field",
                { attrs: { label: "הצג קורסים פעילים" } },
                [
                  _c(
                    "b-switch",
                    {
                      staticClass: "ltr pt-2",
                      model: {
                        value: _vm.isManaged,
                        callback: function($$v) {
                          _vm.isManaged = $$v
                        },
                        expression: "isManaged"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.isManaged ? "כן" : "לא") + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "Admin__View__Profiles__Table" },
        [
          _c(
            "b-table",
            {
              attrs: {
                paginated: "",
                "sticky-header": true,
                data: _vm.data,
                "pagination-simple": true,
                hoverable: true,
                loading: _vm.isLoading,
                "per-page": "30",
                "default-sort-direction": "asc",
                "row-class": function(row, index) {
                  return _vm.addRowClass(row)
                },
                "default-sort": "Id",
                "mobile-cards": true
              },
              on: { click: _vm.onRowClick }
            },
            [
              _c("b-table-column", {
                attrs: { field: "ןd", label: "מזהה", centered: "" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.id) + " ")]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { field: "מame", label: "שם הקורס" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [_vm._v(" " + _vm._s(props.row.name) + " ")]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { field: "מame", label: "שכפול קורס" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.duplicateCourse(props.row)
                                  }
                                }
                              },
                              [
                                _vm._v(" שכפל "),
                                _c("b-icon", {
                                  staticStyle: { padding: "25px" },
                                  attrs: { pack: "fa", icon: "copy" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { field: "מame", label: "עריכת שם קורס" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.duplicateCourse(props.row, true)
                                  }
                                }
                              },
                              [
                                _vm._v(" ערוך "),
                                _c("b-icon", {
                                  staticStyle: { padding: "25px" },
                                  attrs: { pack: "fa", icon: "edit" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("b-table-column", {
                attrs: { field: "מame", label: "הגדרות" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(props) {
                      return [
                        _c(
                          "div",
                          [
                            _c(
                              "b-button",
                              {
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.changeCourseStatus(props.row)
                                  }
                                }
                              },
                              [
                                _c("b-icon", {
                                  staticStyle: { padding: "25px" },
                                  attrs: { pack: "fa", icon: "cog" }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }