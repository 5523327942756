<template>
    <div class="DialogAddLaw__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ isEdit ? 'עריכת' : 'שכפול' }} קורס - {{ course.name }}</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogAddLaw__Component__Loading">
                    <b-field label="הזן שם לקורס החדש">
                        <b-input v-model="model.name"></b-input>
                    </b-field>
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import AdminService from '../services/AdminService';

export default {
    name: "DialogDuplicateCourse",
    props: ["course", "onSuccess", "isEdit"],
    components: {
        FieldInlineSelect
    },
    created() {
        this.model = {
            name: this.course.name
        };
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            model: {
                name: null,
            }
        };
    },
    methods: {
        save() {
            this.isLoading = true;
            if (this.isEdit) {
                AdminService.changeCourseName(this.course.id, this.model)
                    .then(() => {
                        this.$buefy.toast.open({
                            type: "is-success",
                            message: `הקורס עודכן בהצלחה!`,
                            duration: 4000,
                        });
                        this.$emit("close");
                        this.onSuccess();
                    }).catch(() => {
                        this.$buefy.toast.open({
                            type: "is-danger",
                            message: `הבקשה נכשלה!`,
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    })
            } else {
                if (this.course.name === this.model.name) {
                    this.$buefy.toast.open({
                        type: "is-danger",
                        message: `השם זהה לקורס הקיים!`,
                        duration: 8000,
                    });
                    this.isLoading = false;
                    return;
                }
                AdminService.duplicateCourse(this.course.id, this.model)
                    .then(() => {
                        this.$buefy.toast.open({
                            type: "is-success",
                            message: `הקורס שוכפל בהצלחה!`,
                            duration: 4000,
                        });
                        this.$emit("close");
                        this.onSuccess();
                    }).catch(() => {
                        this.$buefy.toast.open({
                            type: "is-danger",
                            message: `הבקשה נכשלה!`,
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    })
            }
        },
    },
};
</script>

<style lang="scss"></style>
