<template>
    <div class="DialogAddLaw__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">שיוך מקבץ פסיקה ליחידת לימוד</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogAddLaw__Component__Loading">
                    <FieldInlineText label="שם התקציר" @onChange="onChange" v-model="model.name" />

                    <FieldInlineText label="נושא ראשי" @onChange="onChange" v-model="model.category" />

                    <FieldInlineText label="נושא משני" @onChange="onChange" v-model="model.subCategory" />

                    <b-taginput @input="onChange" v-model="model.modelSubLabels" ellipsis icon="label"
                        placeholder="הוסף סוגיה" aria-close-label="מחק סוגיה זו">
                    </b-taginput>

                    <FieldInlineText :type="'number'" label="מספר פסק דין" @onChange="onChange"
                        v-model="model.nevoVerdictId" />
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import FieldInlineText from "./Fields/FieldInlineText.vue";
import AdminService from '../services/AdminService';

export default {
    name: "DialogAddVerdict",
    props: ["store"],
    components: {
        FieldInlineText
    },
    computed: {
    },
    data() {
        return {
            submitted: false,
            isLoading: false,
            model: {
                name: null,
                category: null,
                modelSubLabels: null,
                nevoVerdictId: null,
                subCategory: null,
            }
        };
    },
    methods: {
        save() {
            if (this.model.name) {
                this.isLoading = true;
                AdminService.createVerdictSummary({
                    ...this.model
                })
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        this.$emit("close");
                        window.location.reload();
                    }).catch((err) => {
                        let message = '';
                        if (err.response.data === 'Verdict Already Exists') message = 'קיים כבר פסק דין זהה במערכת.';
                        else message = "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית."
                        Toast.open({
                            type: "is-danger",
                            message,
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        onChange() { },
    },
};
</script>

<style lang="scss"></style>
