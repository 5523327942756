var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogAddLaw__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v("שיוך מקבץ פסיקה ליחידת לימוד")
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c("section", { staticClass: "modal-card-body" }, [
        _c(
          "div",
          { staticClass: "DialogAddLaw__Component__Loading" },
          [
            _c("FieldInlineText", {
              attrs: { label: "שם התקציר" },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.name,
                callback: function($$v) {
                  _vm.$set(_vm.model, "name", $$v)
                },
                expression: "model.name"
              }
            }),
            _c("FieldInlineText", {
              attrs: { label: "נושא ראשי" },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.category,
                callback: function($$v) {
                  _vm.$set(_vm.model, "category", $$v)
                },
                expression: "model.category"
              }
            }),
            _c("FieldInlineText", {
              attrs: { label: "נושא משני" },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.subCategory,
                callback: function($$v) {
                  _vm.$set(_vm.model, "subCategory", $$v)
                },
                expression: "model.subCategory"
              }
            }),
            _c("b-taginput", {
              attrs: {
                ellipsis: "",
                icon: "label",
                placeholder: "הוסף סוגיה",
                "aria-close-label": "מחק סוגיה זו"
              },
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.modelSubLabels,
                callback: function($$v) {
                  _vm.$set(_vm.model, "modelSubLabels", $$v)
                },
                expression: "model.modelSubLabels"
              }
            }),
            _c("FieldInlineText", {
              attrs: { type: "number", label: "מספר פסק דין" },
              on: { onChange: _vm.onChange },
              model: {
                value: _vm.model.nevoVerdictId,
                callback: function($$v) {
                  _vm.$set(_vm.model, "nevoVerdictId", $$v)
                },
                expression: "model.nevoVerdictId"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }