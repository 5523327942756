<template>
  <div id="createAnswers">
    <div class="createAnswers-item">
      <h2 style="color: #286695;">יצירת קובץ תשובות</h2>
    </div>
    <template class="verdict-form createAnswers-item">
      <form v-if="!result" @submit.prevent="onSubmit()" class="form">
        <div class="link-container">
          <input type="radio" v-model="isExercise" :value="false">קורס
          <input type="radio" v-model="isExercise" :value="true">תרגולים
        </div>
        <b-taginput v-if="isExercise" v-model="exercises" ellipsis icon="label" placeholder="הזן מספרי תרגולים"
          aria-close-label="מחק תרגולים">
        </b-taginput>
        <input v-else type="text" v-model="course" placeholder="הזן מספר קורס">
        <button class="button-style" v-if="!result">צור קובץ</button>
      </form>
      <div>
        <button class="button-style" style="margin-top: 10px" v-if="result" @click="downloadFile">הורד</button>
        <button class="button-style" style="margin-top: 10px" v-if="result" @click="resetForm">איפוס</button>
      </div>
    </template>
  </div>
</template>
<script>
import { ToastProgrammatic as Toast } from 'buefy';
import AdminService from '../services/AdminService';
const BASE_URL = process.env.VUE_APP_APIURL;

export default {
  name: 'createAnswers',
  data: function () {
    return {
      isLoading: false,
      isExercise: false,
      exercises: [],
      course: null,
      result: null
    }
  },
  methods: {
    onSubmit() {
      this.isLoading = true;
      if (this.isExercise) {
        this.$api.post(BASE_URL + '/api/admin/answers', {
          Exercises: this.exercises
        }).then((data) => {
          this.result = data.data;
          this.isLoading = false;
        }).catch((error) => {
          console.log(error);
          this.isLoading = false;
          Toast.open({
            type: 'is-danger',
            message: 'פעולה נכשלה!',
            position: 'is-top',
            duration: 8000
          });
        });
      } else {
        AdminService.getAnswersFile(this.course).then((data) => {
          this.result = data.data;
          this.isLoading = false;
        }).catch((error) => {
          console.log(error);
          this.isLoading = false;
          Toast.open({
            type: 'is-danger',
            message: 'פעולה נכשלה!',
            position: 'is-top',
            duration: 8000
          });
        });
      }
    },
    downloadFile() {
      AdminService.downloadAnswersFile({
        Path: this.result
      }).then(async (data) => {
        const href = URL.createObjectURL(data.data);
        const name = this.result.split('answers');
        const fileName = 'answer' + name[1] + '.csv';
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        this.resetForm();
      }).catch((error) => {
        let message = '';
        if (error.response.status === 404) { 
          message = 'הקובץ עוד לא נוצר, נסו שנית בעוד מספר רגעים.';
        } else {
          message = 'פעולה נכשלה!';
        }
        this.isLoading = false;
        // this.$store.dispatch('stopLoader');
        Toast.open({
          type: 'is-danger',
          message,
          position: 'is-top',
          duration: 8000
        });
      });
    },
    resetForm() {
      this.exercises = null;
      this.course = null;
      this.result = null;
    }
  }
}
</script>
<style>
#createAnswers {
  max-width: unset;
  width: 100%;
  padding: 20px;
  color: #333;
  display: flex;
  flex-direction: column;
}

.has-ellipsis {
  max-width: 10em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tag {
  margin-left: 0.275rem;
  margin-bottom: calc(0.275em - 1px);
  font-size: .9em;
  height: calc(2em - 1px);
}

.tag:not(body) {
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  color: #4a4a4a;
  display: inline-flex;
  font-size: .75rem;
  height: 2em;
  justify-content: center;
  line-height: 1.5;
  padding-left: 0.75em;
  padding-right: 0.75em;
  white-space: nowrap;
}

.tag .delete:before,
.modal-close:before {
  height: 2px;
  width: 50%;
  background-color: #fff;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
}

.tag .delete:after,
.modal-close:after {
  height: 50%;
  width: 2px;
  background-color: #fff;
  content: "";
  display: block;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
  transform-origin: center center;
}

#createAnswers>form>div.taginput.control>div>span>a {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: hsla(0, 0%, 4%, .2);
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  outline: none;
  position: relative;
  vertical-align: top;
  width: 20px;
}

#createAnswers .add-verdict {
  display: inline-block;
  width: 140px;
  line-height: 38px;
  color: #286695;
  border: 1px solid #286695;
  background: #fff;
  border-radius: 4px;
  outline: none;
  margin-left: 15px;
  font-weight: bold;
}

.createAnswers-item {
  width: 50%;
}

.createAnswers-container {
  display: flex;
  margin-top: 15px;
  gap: 25px;
}

.createAnswers-container .verdict-items {
  gap: 15px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.createAnswers-container .verdict-form {
  gap: 15px;
  display: flex;
  flex-direction: column;
  width: 50%;
}

.form {
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 15px;
}

.link-container {
  display: flex;
  align-items: center;
}

.link-container .link {
  text-decoration: underline;
}

.verdict-form h1 {
  margin: 0;
  padding: 0;
}

.form textarea,
.form input {
  border-radius: 7px;
  font-size: 20px;
  padding: 0 5px;
  resize: vertical;
  width: 100%;
}

.form input {
  border: 1px solid rgb(118, 118, 118);
  padding: 15px 5px;
  background-color: #fff;
  font-size: 16px;
}

.button-style {
  background-color: #286695;
  padding: 15px;
  color: white;
  border-radius: 7px;
  width: fit-content;
}

.createAnswers-container .verdict-item {
  background: #3c8ac966;
  font-weight: bold;
  font-size: 16px;
  position: relative;
  max-width: 400px;
  padding: 10px 0;
  cursor: pointer;
}

#createAnswers h2 {
  margin: 20px 0;
}

#createAnswers h1,
#createAnswers h2,
#createAnswers h3 {
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  color: #111;
  font-size: 2em;
}
</style>
<style scoped>
.button .icon,
.button .icon.is-small,
.button .icon.is-medium,
.button .icon.is-large {
  height: 1.5em;
  width: 1.5em;
}

.button.is-small {
  font-size: 0.75rem;
}

.delete.is-small {
  margin-left: 0.25rem;
  margin-right: -0.375rem;
}

.is-small.delete,
.is-small.modal-close {
  height: 16px;
  max-height: 16px;
  max-width: 16px;
  min-height: 16px;
  min-width: 16px;
  width: 16px;
}

.delete,
.modal-close {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background-color: hsla(0, 0%, 4%, .2);
  border: none;
  border-radius: 9999px;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 0;
  height: 20px;
  max-height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  outline: none;
  position: relative;
  vertical-align: top;
  width: 20px;
}
</style>

<style>
#mainDisplay {
  right: 180px;
  width: calc(100% - 180px);
}
</style>
