<template>
    <div class="DialogAddLaw__Component">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">מקבץ חקיקה חדש</p>
                <button type="button" class="delete" @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <div class="DialogAddLaw__Component__Loading">
                    <FieldInlineText label="שם" @onChange="onChange" v-model="model.name" />
                    <div>
                        <b-checkbox style="gap: 10px" v-model="model.displaySeperateLawText" label=""
                            type="is-link is-light" class="checkbox">
                            מקבץ מסוג דרופדאון
                        </b-checkbox>
                    </div>
                    <div>
                        <b-checkbox style="gap: 10px" v-model="model.isAcademy" label="" type="is-link is-light"
                            class="checkbox">
                            אקדמיה
                        </b-checkbox>
                    </div>
                    <div>
                        <b-checkbox style="gap: 10px" v-model="model.isActive" label="" type="is-link is-light"
                            class="checkbox">
                            פעיל
                        </b-checkbox>
                    </div>

                    <FieldInlineSelect label="בחר חוק מהרשימה" :filterable="true" :clearable="true"
                        :reduce="item => item" :optionsAsync="getLaws" @onChange="searchLawClauses"
                        v-model="chosenLaw" />

                    <div v-if="chosenLaw" class="new-law">
                        <div class="item">
                            <p>{{ chosenLaw.Text }}</p>
                            <!-- <input type="number" v-model="chosenLaw.sortOrder"> -->
                            <div style="cursor: pointer" @click="addVerdictItem()" class="add">
                                <b-icon class="" pack="fa" icon="plus"></b-icon>
                            </div>
                        </div>
                    </div>

                    <div v-if="clauses.length" class="clauses">
                        <div @click="addClauseToLaw(clause)" v-for="clause in clauses"
                            :class="{ 'clicked': clicked.includes(clause.id) }" :key="clause.id" class="clause">
                            {{ clause.name }}
                        </div>
                    </div>

                    <!-- <div v-if="model.items.length" class="clauses items">
                        <div v-for="item, idx in model.items" :key="idx" class="item">
                            <div>{{ item.lawId }}</div>
                            <input type="number" v-model="item.sortOrder" name="" id="">
                        </div>
                    </div> -->
                </div>
            </section>
            <footer class="modal-card-foot">
                <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
                <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
            </footer>
        </div>
    </div>
</template>

<script>
import { ToastProgrammatic as Toast } from "buefy";
import FieldInlineText from "./Fields/FieldInlineText.vue";
import FieldInlineSelect from "./Fields/FieldInlineSelect.vue";
import AdminService from '../services/AdminService';

export default {
    name: "DialogAddLawCollection",
    props: ["store", "row"],
    components: {
        FieldInlineText,
        FieldInlineSelect
    },
    computed: {
    },
    created() {
        // if (this.row) {
        //     AdminService.getLawCollection(this.row.id)
        //         .then((r) => {
        //             this.model = r.data;
        //         });
        //     this.model.items.map((i, idx) => {
        //         AdminService.getLawClauses(i.lawId)
        //             .then((r) => {
        //                 this.items[idx] = r.data;
        //             });
        //     });
        //     console.log(this.model);
        // }
    },
    data() {
        return {
            isLoading: false,
            model: {
                name: null,
                displaySeperateLawText: false,
                items: []
            },
            law: null,
            chosenLaw: null,
            clauses: [],
            clicked: []
        };
    },
    methods: {
        getLaws() {
            return () =>
                AdminService.getLaws().then((r) =>
                    r.data.map((i) => ({ Value: i.id, Text: i.name, sortOrder: 1 }))
                );
        },
        addVerdictItem() {
            // const newItem = {
            //     name: this.chosenLaw.Text,
            //     lawId: this.chosenLaw.Value,
            //     sortOrder: this.chosenLaw.sortOrder++
            // };
            // this.model.items.push(newItem);
            this.chosenLaw = null;
            this.clauses = [];
        },
        searchLawClauses() {
            AdminService.getLawClauses(this.chosenLaw.Value)
                .then((r) => {
                    this.clauses = r.data;
                });
        },
        addClauseToLaw(clause) {
            if (this.clicked.includes(clause.id)) {
                const idx = this.clicked.indexOf(clause.id);
                this.clicked.splice(idx, 1);
            } else {
                this.clicked.push(clause.id);
                const newItem = {
                    lawId: this.chosenLaw.Value,
                    clauseId: clause.id,
                    sortOrder: this.chosenLaw.sortOrder
                };
                this.model.items.push(newItem);
            }
        },
        save() {
            if (this.model.name && this.model.items.length) {
                this.isLoading = true;
                AdminService.createLawCollection({
                    ...this.model
                })
                    .then(() => {
                        Toast.open({
                            type: "is-success",
                            message: "הפעולה בוצעה!",
                            duration: 4000,
                        });
                        this.$emit("close");
                        window.location.reload();
                    }).catch(() => {
                        Toast.open({
                            type: "is-danger",
                            message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
                            duration: 8000,
                        });
                    }).finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        onChange() { },
    },
};
</script>

<style scoped lang="scss">
.clauses {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: 400px;
    overflow: auto;
}

.items {
    flex-direction: column;
}

.clause {
    min-width: 40px;
    height: 30px;
    background: #fff;
    border: 1px solid #333;
    border-radius: 4px;
    color: #333;
    padding: 5px;
    margin: 5px 0 5px 10px;
    cursor: pointer;
}

.items {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.item {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
        flex: 1;
    }

    input {
        width: 50px;
    }
}

.clauses {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-height: 400px;
    overflow: auto;
}

.clause {
    min-width: 40px;
    height: 30px;
    background: #fff;
    border: 1px solid #333;
    border-radius: 4px;
    color: #333;
    padding: 5px;
    margin: 5px 0 5px 10px;
}

.clicked {
    background-color: gray;
}
</style>
